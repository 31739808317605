@use "sass:math";

@mixin calc($key, $value)
{
  #{$key}: -webkit-calc(#{$value});
  #{$key}: -moz-calc(#{$value});
  #{$key}: calc(#{$value});
}

input
{
  font-size: 20px;
}

body
{
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root
{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

code
{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


body
{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.section100
{
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
}

@media (max-width: 768px)
{
  .sectionHome
  {
    margin: 0;
  }

  .sectionNDM
  {
    margin: 0;
  }

  .sectionCR
  {
    margin: 0;
  }

  .section2Cols
  {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 769px)
{
  .sectionHome
  {
    margin: 0;
    padding-left: 10%;
    padding-right: 10%;
  }

  .sectionNDM
  {
    margin: 0;
    padding-left: 10%;
    padding-right: 10%;
  }

  .sectionCR
  {
    margin: 0;
    padding-left: 5%;
    padding-right: 5%;
  }

  .section2Cols
  {
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 1200px)
{
  .sectionNDM
  {
    margin: 0;
    padding-left: 15%;
    padding-right: 15%;
  }
}

@media (min-width: 1400px)
{
  .sectionNDM
  {
    margin: 0;
    padding-left: 20%;
    padding-right: 20%;
  }
}

.margFetes
{
  margin-top: 20px;
}

.liFetes
{
  margin-left: 20px;
  margin-top: 10px;
}

.presentationFetes
{
  font-size: 20px;
  padding: 20px 100px;
  background-color: white;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

.sectionAdmin
{
  background-color: rgba(97,196,212, 0.2);
}

.sectionCommissions
{
  background-color: rgba(97,196,212, 0.2);
}

.sectionAssociation
{
  background-color: rgba(97,196,212, 0.2);
}

.sectionPost
{
  background-color: rgba(97,196,212, 0.2);
}

.sectionFetes
{
  background-color: rgba(97,196,212, 0.2);
  margin: 0;
  padding-left: 5%;
  padding-right: 5%;
}

.containerNDM
{
  display: flex;
  justify-content: center;
}

.containerCommissions
{
  width: 100%;
  flex-direction: column;
}

.containerPostCommissions
{
  padding: 0;
}

@media (max-width: 768px)
{
  .containerFooter
  {
    margin-right: 5%;
    margin-left: 5%;
    padding-right: 10px;
    padding-left: 10px;
  }

  .containerAdmin
  {
    width: 100%;
    flex-direction: column;
  }

  .adminMenuLink
  {
    padding: 5%;
  }
}

@media (min-width: 769px)
{
  .containerFooter
  {
    margin-right: 10%;
    margin-left: 10%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .containerAdmin
  {
    width: 100%;
    flex-direction: row;
  }

  .adminMenuLink
  {
    padding: 5%;
  }
}

.containerEvent
{
  margin: 150px 0 0 0;
  display: flex;
  justify-content: center;
}

.containerPostCommissions h1
{
  height: 80px;
  text-align: center;
  line-height: 80px;
  width: 50vw;
  background-color: white;
  color: #f75f57;
  font-weight: 600;
  font-size: 30px;
  border-bottom: 1px solid #f75f57;
  margin-top: 0;
  margin-left: 25vw;
  margin-bottom: 50px;
}

.btnClasses, .boutonClasses
{
  background-color: rgb(109, 108, 168);
  border-color: rgb(109, 108, 168);
  cursor: pointer;
  color: white;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  border-width: 1px;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.react-pdf__Page__canvas
{
  width: 100%! important;
  height: 100%! important;
}

.react-pdf__Page__textContent, .react-pdf__Page__annotations
{
  display: none;
}

.btnClasses:hover, .boutonClasses:hover
{
  background-color: rgb(69, 68, 169);
  border-color: rgb(69, 68, 169);
}

.home
{
  width: 100%;
  height: 100vh;
  /*background-image: url('../../images/P1050968.jpg');*/
  background-image: url('../../images/p0006.jpg');
  background-position: center top;
  background-size:cover;
  position: fixed;
  margin: 0;
  z-index: -1;
}

.homeOverlay
{
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
}

.commissions
{
  width: 100%;
  height: 100vh;
  background-color: rgba(97,196,212, 0.2);
  position: fixed;
  margin: 0;
  z-index: -1;
}

.cop
{
  width: 100%;
  height: 100vh;
  background-color: rgba(250,179,77,0.1);
  position: fixed;
  margin: 0;
  z-index: -1;
}

.containerCOP
{
  margin: 150px 0 0 0;
  display: flex;
  justify-content: center;
}

.imgPostHome
{
  width: 100%;
  height: auto;
}

.categoryPost
{
  font-size: 20px;
  text-align: center;
  color: #f75f57;
}

@media (max-width: 768px)
{
  .titlePost
  {
    font-size: 20px;
    text-align: center;
    font-weight: 700;
  }

  .postHeader
  {
    margin-bottom: 20px;
  }

  .resumePost
  {
    font-size: 20px;
    margin: 0;
    line-height: 40px;
    font-weight: 500;
    /* word-wrap: break-word; */
    word-break: break-word;
    overflow: hidden;
  }

  .fullPost
  {
    padding: 15px 15px;
    background-color: rgba(255, 255, 255, 0.8);
  }
}

@media (min-width: 769px)
{
  .titlePost
  {
    font-size: 30px;
    text-align: center;
    font-weight: 700;
  }

  .postHeader
  {
    margin-bottom: 10px;
  }

  .resumePost
  {
    font-size: 20px;
    margin: 0;
    line-height: 40px;
    font-weight: 500;
    /* word-wrap: break-word; */
    word-break: break-word;
    overflow: hidden;
  }

  .fullPost
  {
    padding: 30px 30px;
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.descriptionPostEncart
{
  margin-top: 5px;
}

.footerPost
{
  border-top: 1px #fab34d solid;
  background-color: rgba(255, 255, 255, 1);
  padding: 30px;
}

.headerPost
{
  border-bottom: 1px #fab34d solid;
  background-color: rgba(255, 255, 255, 1);
  padding: 30px;
}

.footerPost a, .footerPost Link
{
  font-size: 20px;
  padding: 15px;
  border: 1px #fab34d solid;
  letter-spacing: 1px;
  font-weight: 600;
  text-decoration: none;
  color: black;
  transition: 0.5s ease;
}

.footerPost a:hover
{
  background-color: #fab34d;
}

@media (max-width: 768px)
{
  .form-wrapper
  {
    display: flex;
    flex-direction: column;
    width: 560px;
    max-width: 80%;
    min-width: 100px;
    min-height: 400px;
    padding: 20px 10px;
    border-radius: 6px;
    box-shadow: 0 8px 36px #222;
    background-color: #fefefe;
    margin-top: 50px;
  }

  .form-wrapper > h2
  {
    display: flex;
    justify-content: center;
    font-size: 20px;
    margin-top: 0.25em;
    color: #222;
    font-weight: 500;
  }

  .form-wrapper label
  {
    margin-bottom: 0.5em;
    color: #444;
    font-weight: 500;
    font-size: 20px;
  }

  .form-wrapper .submit
  {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .form-wrapper button
  {
    min-width: 100%;
    cursor: pointer;
    margin-right: 0.25em;
    margin-top: 0.5em;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #22223B;
    color: #fefefe;
    font-size: 20px;
    font-weight: 600;
  }

  /* Utilisé pour commissions fêtes */
  .ulPost
  {
    list-style-type: none;
    padding: 0;
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
  }

  .fullOnePost
  {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 50px;
  }

  .titleOnePost
  {
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    padding: 10px;
    word-break: break-word;
    overflow: hidden;
  }

  .descriptionOnePost
  {
    padding: 5px;
    display: flex;
    justify-content: flex-start;
    word-break: break-word;
    overflow: hidden;
  }
}

@media (min-width: 769px)
{
  .form-wrapper
  {
    display: flex;
    flex-direction: column;
    width: 560px;
    max-width: 80%;
    min-width: 100px;
    min-height: 400px;
    padding: 20px 40px;
    border-radius: 6px;
    box-shadow: 0 8px 36px #222;
    background-color: #fefefe;
    margin-top: 50px;
  }

  .form-wrapper > h2
  {
    display: flex;
    justify-content: center;
    font-size: 30px;
    margin-top: 0.25em;
    color: #222;
    font-weight: 500;
  }

  .form-wrapper label
  {
    margin-bottom: 0.5em;
    color: #444;
    font-weight: 500;
    font-size: 20px;
  }

  .form-wrapper .submit
  {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .form-wrapper button
  {
    min-width: 100%;
    cursor: pointer;
    margin-right: 0.25em;
    margin-top: 0.5em;
    padding: 	0.938em;
    border: none;
    border-radius: 4px;
    background-color: #22223B;
    color: #fefefe;
    font-size: 20px;
    font-weight: 600;
  }

  /* Utilisé pour commissions fêtes */
  .ulPost
  {
    list-style-type: none;
    padding: 0;
    margin-left: 20%;
    margin-right: 20%;
    width: 60%;
  }

  .fullOnePost
  {
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 50px;
  }

  .titleOnePost
  {
    font-size: 30px;
    text-align: center;
    font-weight: 700;
    padding: 30px;
    word-break: break-word;
    overflow: hidden;
  }

  .descriptionOnePost
  {
    padding: 5px;
    display: flex;
    justify-content: flex-start;
  }
}

@media (min-width: 1200px)
{
  /* Utilisé pour commissions fêtes */
  .ulPost
  {
    list-style-type: none;
    padding: 0;
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
  }

  .fullOnePost
  {
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 50px;
  }

  .titleOnePost
  {
    font-size: 30px;
    text-align: center;
    font-weight: 700;
    padding: 30px;
    word-break: break-word;
    overflow: hidden;
  }

  .descriptionOnePost
  {
    padding: 5px;
    display: flex;
    justify-content: flex-start;
  }
}

/* Utilisé pour commissions fêtes */
.ulPost li
{
  margin-bottom: 50px;
}

.imgOnePostHome
{
  width: 100%;
  height: auto;
}

.contentOnePost
{
  font-size: 20px;
  margin-bottom: 15px;
  line-height: 20px;
  font-weight: 500;
  /*word-wrap: break-word;*/
  word-break: break-word;
  overflow: hidden;
}

.containerImageCOP
{
  display: flex;
  justify-content: center;
}

.referenceOnePost
{
  padding: 10px;
  text-align: right;
}

.referencePost
{
  font-size: 15px;
  padding-top: 10px;
}

.contentReport
{
  font-size: 20px;
  margin: 30px 0 15px 0;
  line-height: 20px;
  font-weight: 500;
}

.footerReport
{
  justify-content: center;
  font-size: 20px;
  margin: 30px 0 30px 0;
}

.mainFooter
{
  padding: 20px 0;
  background-color: rgb(109, 108, 168);
}

footer ul
{
  padding-left: 0;
  list-style: none;
}

.footer-top
{
  background-color: rgb(109, 108, 168);
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 3px solid #222;
}

.widget
{
  padding: 0;
  margin-bottom: 10px;
}

.mainFooter a
{
  color: white;
}

.footerTitle
{
  margin-bottom: 20px;

}

.footerFont
{
  color: black;
  font-size: 20px;
  font-weight: 500
}

.btnFooter
{
  background-color: #4b92dc;
  color: white;
  padding: 10px;
  font-size: 20px;
  font-weight: 500;
}

.btnFooter:hover
{
  background-color: white;
  color: #4b92dc;
}

hr
{
  border: 1px white solid;
}

.footerFixed
{
  position: fixed;
  bottom: 0;
  width: 100%;
}

.imgOneEventHome
{
  width: 100%;
  height: auto;
}

.fullOneEvent
{
  width: 1000px;
  background-color: white;
  margin-bottom: 50px;
}

.titleOneEvent
{
  font-size: 30px;
  text-align: center;
  font-weight: 700;
  padding: 30px;
}

.descriptionOneEvent
{
  padding: 30px;
  display: flex;
  justify-content: center;
}

.contentOneEvent
{
  font-size: 20px;
  margin-bottom: 15px;
  line-height: 20px;
  font-weight: 500;
  width: 100%;
}

.thBackground
{
  background-color: rgba(97,196,212, 0.2);
}


.containerImageEvent
{
  display: flex;
  justify-content: center;
}

.referenceOneEvent
{
  padding: 10px;
  text-align: right;
}

#reservation
{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#reservation h2
{
  margin-top: 150px;
  text-align: center;
}

tbody hr
{
  margin: 0;
}

.buttonOrder
{
  background-color: rgba(97,196,212, 1);
  color: white;
  margin: 20px auto;
  padding: 10px 30px;
  font-size: 20px;
  font-weight: 600;
}

.buttonOrder
{
  cursor: pointer;
}

.coordonnees
{
  width: 100%;
  height: 100vh;
  background-color: rgba(97,196,212, 0.2);
  position: fixed;
  margin: 0;
  z-index: -1;
}

.descriptionCoordonnees
{
  padding: 20px;
  font-size: 20px;
  margin-bottom: 15px;
  line-height: 20px;
  font-weight: 500;
}

.wrapper
{
  margin: 150px 0 50px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-wrapper form
{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.info
{
  padding-bottom: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.form-wrapper input, .form-wrapper select
{
  padding: 10px 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #d6d1d5;
  font-size: 20px;
}

.form-wrapper input::placeholder
{
  font-size: 20px;
  font-weight: lighter;
  color: #bbb;
}

.form-wrapper button:hover
{
  background-color: #4A4E69;
  color: #fefefe;
}

.form-wrapper input.error
{
  border: 1px solid #EADAE4;
}

.messageError
{
  font-size: 20px;
  text-align: center;
  padding: 10px;
  color: #db2269;
}

.error
{
  color:#db2269;
  font-size: 20px;
}

.imgAttachement
{
  width: 50px;
}

.OnePostContainer
{
  /*background-color: rgba(255, 255, 255, 0.3);*/
  width: 100%;
}

.form-wrapper .firstname, .form-wrapper .lastname, .form-wrapper .email,
.form-wrapper .password, .form-wrapper .phone, .form-wrapper .firstnameChild,
.form-wrapper .lastnameTeacher, .form-wrapper .implantation, .form-wrapper .classChild, .form-wrapper .textareaContact,
.form-wrapper .firstnameContact, .form-wrapper .lastnameContact, .form-wrapper .emailContact,.form-wrapper .phoneContact
{
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
}

.implantationVertical
{
  display: flex;
  align-items: center;
}

.implantationVertical input, .implantationVertical label
{
  margin-right: 15px;
}

.implantationVertical label
{
  margin-bottom: 0px;
}

.containerFetesLeft h1
{
  height: 80px;
  text-align: center;
  line-height: 80px;
  background-color: white;
  color: #f75f57;
  font-weight: 600;
  font-size: 28px;
  border-bottom: 1px solid #f75f57;
  margin-top: 0;
  margin-bottom: 50px;
}

#carousel
{
  height: 400px;
  width: 90vw;
  margin: 0 auto;
}

.arrow
{
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: white;
  text-align: center;
  font-size: 25px;
  border-radius: 50%;
  cursor: pointer;
  color: #FB8C00;
  line-height: 30px;
  margin-top: 185px;
  z-index: 60;
}

.arrow-left
{
  left: 5vw;
}

.arrow-right
{
  right: 5vw;
}

.item
{
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  background-color: rgba(109, 108, 168, 1);
  transition: height 1s, width 1s, left 1s, margin-top 1s, line-height 1s, background-color 1s;
}

$level2-height: 350px;
$level2-width: 210px;
$level1-height: 380px;
$level1-width: 230px;
$level0-height: 400px;
$level0-width: 250px;
$space1: 6vw;
$space2: 4vw;

$math: math.div($level0-width, 2);
$level0-left: calc(50vw - #{$math});
$level-1-left: calc(#{$level0-left} - #{$space1} - #{$level1-width});
$level1-left: calc(#{$level0-left} + #{$space1} + #{$level0-width});
$level-2-left: calc(#{$level-1-left} - #{$space2} - #{$level2-width});
$level2-left: calc(#{$level1-left} + #{$space2} + #{$level1-width});

.sliderDescription
{
  text-align: center;
  padding: 5px;
  transition: font-size 1s;
}

.sliderName
{
  transition: font-size 1s;
}


.imgBoards
{
  object-fit: cover;
  transition: height 1s, width 1s;
}

.imgBoardCont
{
  background-color: white;
}

.level-3
{
  height: $level2-height;
  width: $level2-width;
  left: $level-2-left;
  margin-top: 25px;
  opacity: 0;
  z-index: -1;
  transition: opacity 1s;

  .sliderName
  {
    font-size: 8px;
    font-weight: 600;
  }

  .sliderDescription
  {
    font-size: 8px;
  }

  .imgBoards
  {
    height: 210px;
    width: 210px;
  }
}

.level3
{
  height: $level2-height;
  width: $level2-width;
  margin-top: 25px;
  left: $level2-left;
  opacity: 0;
  z-index: -1;
  transition: opacity 1s;

  .sliderName
  {
    font-size: 8px;
    font-weight: 600;
  }

  .sliderDescription
  {
    font-size: 8px;
  }

  .imgBoards
  {
    height: 210px;
    width: 210px;
  }
}

.level-2
{
  height: $level2-height;
  width: $level2-width;
  left: $level-2-left;
  margin-top: 25px;
  z-index: 0;

  .sliderName
  {
    font-size: 12px;
    font-weight: 600;
  }

  .sliderDescription
  {
    font-size: 12px;
  }

  .imgBoards
  {
    height: 210px;
    width: 210px;
  }
}

.level2
{
  height: $level2-height;
  width: $level2-width;
  margin-top: 25px;
  left: $level2-left;
  z-index: 0;

  .sliderName
  {
    font-size: 12px;
    font-weight: 600;
  }

  .sliderDescription
  {
    font-size: 12px;
  }

  .imgBoards
  {
    height: 210px;
    width: 210px;
  }
}

.level-1
{
  height: $level1-height;
  width: $level1-width;
  left: $level-1-left;
  margin-top: 10px;
  z-index: 1;

  .sliderName
  {
    font-size: 16px;
    font-weight: 600;
  }

  .sliderDescription
  {
    font-size: 14px;
  }

  .imgBoards
  {
    height: 230px;
    width: 230px;
  }
}

.level1
{
  height: $level1-height;
  width: $level1-width;
  margin-top: 10px;
  left: $level1-left;
  z-index: 1;

  .sliderName
  {
    font-size: 16px;
    font-weight: 600;
  }

  .sliderDescription
  {
    font-size: 14px;
  }

  .imgBoards
  {
    height: 230px;
    width: 230px;
  }
}

.level0
{
  height: $level0-height;
  width: $level0-width;
  left: $level0-left;
  z-index: 2;

  .sliderName
  {
    font-size: 20px;
    font-weight: 600;
  }

  .sliderDescription
  {
    font-size: 15px;
  }

  .imgBoards
  {
    height: 250px;
    width: 250px;
  }
}

.levelPdf
{
  height: auto! important;
}

.left-enter
{
  opacity: 0;
  left: calc(#{$level2-left} - #{$level2-width});
  height: calc(#{$level2-height} - 30);
  width: calc(#{$level2-width} - 20);
  line-height: calc(#{$level2-height} - 30);
  margin-top: 40px;

   &.left-enter-active
  {
    opacity: 1;
    left: $level2-left;
    height: $level2-height;
    width: $level2-width;
    line-height: $level2-height;
    margin-top: 25px;
    transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
  }
}

.right-enter
{
  opacity: 0;
  left: calc(#{$level-2-left} + #{$level2-width});
  height: calc(#{$level2-height} - 30);
  width: calc(#{$level2-width} - 20);
  line-height: calc(#{$level2-height} - 30);
  margin-top: 40px;

  &.right-enter-active
  {
    left: $level-2-left;
    opacity: 1;
    height: $level2-height;
    margin-top: 25px;
    line-height: $level2-height;
    width: $level2-width;
    transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
  }
}

.left-leave
{
  opacity: 1;
  left: $level-2-left;
  height: $level2-height;
  width: $level2-width;
  line-height: $level2-height;
  margin-top: 25px;

  &.left-leave-active
  {
    left: calc(#{$level-2-left} + #{$level2-width} + 20);
    opacity: 0;
    height: calc(#{$level2-height} - 30);
    line-height: 120px;
    margin-top: 40px;
    width: calc(#{$level2-width} - 20);
    transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
    z-index: -1;
  }
}

.right-leave
{
  left: $level2-left;
  height: $level2-height;
  opacity: 1;
  margin-top: 25px;
  line-height: $level2-height;
  width: $level2-width;

  .right-leave-active
  {
    left: calc(#{$level2-left} - #{$level2-width});
    opacity: 0;
    height: calc(#{$level2-height} - 30);
    width: calc(#{$level2-width} - 20);
    line-height: calc(#{$level2-height} - 30);
    margin-top: 40px;
    transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
  }
}

.noSelect
{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.council
{
  width: 100%;
  height: 100vh;
  background-color: rgba(247,95,87,0.1);
  position: fixed;
  margin: 0;
  z-index: -1;
}

.custom-select
{
  height: auto;
}

.input-group-prepend
{
  width: 50%;
}

.containerChoice
{
  margin-bottom: 25px;
}

.board
{
  width: 100%;
  height: 100vh;
  background-color: rgba(247,95,87,0.1);
  position: fixed;
  margin: 0;
  z-index: -1;
}

@media (max-width: 768px)
{
  .h1Board
  {
    height: 60px;
    text-align: center;
    line-height: 60px;
    width: 90%;
    background-color: white;
    color: #f75f57;
    font-weight: 600;
    font-size: 20px;
    border-bottom: 1px solid #f75f57;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  .input-groupBoard
  {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 769px)
{
  .h1Board
  {
    height: 80px;
    text-align: center;
    line-height: 80px;
    width: 50vw;
    background-color: white;
    color: #f75f57;
    font-weight: 600;
    font-size: 30px;
    border-bottom: 1px solid #f75f57;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }
  .input-groupBoard
  {
    width: 20%;
    margin-left: auto;
    margin-right: auto;
  }

}

.containerChoice
{
  margin-bottom: 25px;
}

.association
{
  width: 100%;
  height: 100vh;
  background-color: rgba(247,95,87,0.1);
  position: fixed;
  margin: 0;
  z-index: -1;
}

.videoBlock
{
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 768px)
{
  video
  {
    width: 90%;
  }

  .videoBlock h1
  {
    height: 80px;
    text-align: center;
    line-height: 80px;
    margin: 0;
    width: 90%;
    background-color: white;
    color: #f75f57;
    font-weight: 600;
    font-size: 30px;
    border-bottom: 1px solid #f75f57;
  }
}

@media (min-width: 769px)
{
  video
  {
    width: 70%;
  }

  .videoBlock h1
  {
    height: 80px;
    text-align: center;
    line-height: 80px;
    margin: 0;
    width: 70%;
    background-color: white;
    color: #f75f57;
    font-weight: 600;
    font-size: 30px;
    border-bottom: 1px solid #f75f57;
  }
}

@media (min-width: 1200px)
{
  video
  {
    width: 60%;
  }

  .videoBlock h1
  {
    height: 80px;
    text-align: center;
    line-height: 80px;
    margin: 0;
    width: 60%;
    background-color: white;
    color: #f75f57;
    font-weight: 600;
    font-size: 30px;
    border-bottom: 1px solid #f75f57;
  }
}

.containerPostAssociation
{
  margin: 50px 0 0 0;
}


.zama-form *
{
  box-sizing: border-box;
}

.sign-in-container h1,.sign-up-container h1
{
  font-weight: bold;
  margin: 0;
  font-size: 30px;
  padding: 10px 0;
  color: rgb(109, 108, 168);
}

.overlay-container h1
{
  font-weight: bold;
  margin: 0;
  font-size: 30px;
  padding: 10px 0;
  color: white;
}

.zama-form h2
{
  text-align: center;
}

.zama-form p
{
  font-size: 20px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

.zama-form span
{
  font-size: 20px;
}

.zama-form a
{
  color: #333;
  font-size: 20px;
  text-decoration: none;
  margin: 15px 0;
}

.zama-form button
{
  border-radius: 20px;
  border: 1px solid rgb(109, 108, 168);
  background-color: rgb(109, 108, 168);
  color: #FFFFFF;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 40px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  margin-top: 20px;
}

.zama-form button:active
{
  transform: scale(0.95);
}

.zama-form button:focus
{
  outline: none;
}
/*
.zama-form button.ghost
{
    background-color: transparent;
    border-color: #FFFFFF;
}
*/
.zama-form form
{
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

.zama-form input
{
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 15px 0;
  width: 100%;
}

.zama-form .container
{
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25),
  0 10px 10px rgba(0,0,0,0.22);
  position: relative;
  overflow: hidden;
  width: 850px;
  max-width: 100%;
  min-height: 800px;
}

.zama-form .form-container
{
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.zama-form .sign-in-container
{
  left: 0;
  width: 50%;
  z-index: 2;
}

.zama-form .container.right-panel-active .sign-in-container
{
  transform: translateX(100%);
}

.zama-form .sign-up-container
{
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.zama-form .container.right-panel-active .sign-up-container
{
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}



.inscription h1
{
  text-align: center;
  padding: 20px 0 30px 0;
}

@keyframes show {
  0%, 49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%, 100% {
    opacity: 1;
    z-index: 5;
  }
}

.zama-form .overlay-container
{
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 15;
}

.zama-form .container.right-panel-active .overlay-container
{
  transform: translateX(-100%);
}

.zama-form .overlay
{
  /*background: #f6f5f7;*/
  background-color: rgb(109, 108, 168);
  /*background: rgba(247,95,87, 1);*/
  /*background: -webkit-linear-gradient(to right, rgba(247,95,87, 1), rgba(247,95,87, 0.8));
  background: linear-gradient(to right, rgba(247,95,87, 1), rgba(247,95,87, 0.8));*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #FFFFFF;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.zama-form .container.right-panel-active .overlay
{
  transform: translateX(50%);
}

.zama-form .overlay-panel
{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.zama-form .overlay-left
{
  transform: translateX(-20%);
}

.zama-form .container.right-panel-active .overlay-left
{
  transform: translateX(0);
}

.zama-form .overlay-right
{
  right: 0;
  transform: translateX(0);
}

.zama-form .container.right-panel-active .overlay-right
{
  transform: translateX(20%);
}

.zama-form .social-container
{
  margin: 20px 0;
}

.zama-form .social-container a
{
  border: 1px solid #DDDDDD;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

.zama-form footer
{
  background-color: #222;
  color: #fff;
  font-size: 20px;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 20;
}

.zama-form footer p
{
  margin: 10px 0;
}

.zama-form footer i
{
  color: red;
}

.zama-form footer a
{
  color: #3c97bf;
  text-decoration: none;
}

.tableReservation td
{
  text-align: center;
}

.inputReservation
{
  text-align: center;
  font-size: 20px;
}

.containerReservation
{
  margin: 120px 0 0 0;
}

.imgEncart
{
  width: 100%;
  height: auto;
}

.categoryPost
{
  font-size: 20px;
  text-align: center;
  color: #f75f57;
}

.descriptionPost
{
  margin-top: 15px;
}

.footerEncart a:hover
{
  background-color: #fab34d;
}

.ulEncart li
{
  margin-bottom: 50px;
  position: relative;
}

.dateEvent
{
  background-color: rgba(247,95,87,1);
  color: white;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 20px 10px 20px;
}

.dateEventBottom
{
  height: 0;
  margin: 0 0 10px 0;
  border-top: 10px solid rgba(237,85,77,1);
  border-right: 10px solid transparent;
  z-index: 1;
  position: relative;
}

.dateEventWhite
{
  position: absolute;
  bottom: 0;
  height: 10px;
  background-color: rgba(245,250,253,1);
  z-index: 2;
  width: 100%;
}

.tableManagePosts
{
  table-layout: auto;
  width: 100%;
}

#tableManagePosts h1
{
  text-align: center;
  margin-bottom: 50px;
}

.tableManagePosts td, .tableManagePosts th
{
  border: 1px solid black;
  padding: 5px;

  font-size: 20px;
  margin-bottom: 15px;
  line-height: 20px;
  font-weight: 500;
  border-collapse: collapse;
}

.tableExample td, .tableExample th
{
  border: 1px solid black;
  padding: 2px 2px;

  font-size: 20px;
  margin-bottom: 15px;
  line-height: 20px;
  font-weight: 500;
  border-collapse: collapse;
}

.tableManagePosts th
{
  text-align: center;
}

#tableContainerManagePosts
{
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.imgTablePosts
{
  width: 150px;
}

.tdTextAl
{
  text-align: center;
}

.tdCenter
{
  text-align: center;
}

.buttonManageEvent
{
  margin: auto;
}

.tdDescription
{
  min-width: 20%;
}

.imgTableEvents
{
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.imgTableBoards
{
  width: 150px;
}

#containerBackground h2
{
  font-size: 20px;
  font-weight: 400;
  margin: 20px 0 20px 0;
}

@media (max-width: 766px)
{
  #containerBackground
  {
    width: 100%;
  }

  #containerBackground h1
  {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin: 20px 0 0 0;
  }

  .formFont
  {
    font-size: 20px;
    font-weight: 400;
  }
}

@media (min-width: 767px)
{
  #containerBackground
  {
    width: 60%;
  }

  #containerBackground h1
  {
    font-size: 30px;
    font-weight: 800;
    text-align: center;
    margin: 20px 0 0 0;
  }

  .formFont
  {
    font-size: 24px;
    font-weight: 400;
  }
}

#containerBackendView
{
  background-position: top;
  background-size: auto;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

#formBackendView
{
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.width100
{
  width: 100%;
}

#containerBackground
{
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 50px;
}

.flexFormColCenter
{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
}

#postTextarea
{
  margin-top: 30px;
}

.postTextarea
{
  margin-top: 30px;
}

#mytextarea
{
  height: 400px;
}

#uploadZone
{
  width: 100%;
  display: flex;
}

#uploadZone div
{
  margin-right: 10px;
}

.buttonSize
{
  height: 40px;
  width: 120px;

  border: none;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  margin: 15px auto;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #4b92dc;
  color: white;
}

.buttonMember
{
  height: 40px;
  width: 250px;

  border: none;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  margin: 15px 5px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #4b92dc;
  color: white;
}

.formFlex
{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.postTitle
{
  width: 100%;
}

.btnNext, .btnBefore
{
  width: 10vw;
  margin: 15px;
  font-size: 20px;
  font-weight: 600;
  background-color: rgba(255,255,255,0.4);
}

.sectionFormEvent
{
  padding-bottom: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sectionFormEvent h1
{
  padding: 30px 0;
  text-align: center;
}

#sliderCreateEvent>div
{
  display: flex;
  flex-direction: column;
}

.sectionFormEvent form div.containerButton
{
  display: flex;
  flex-direction: row;
}

#sliderCreateEvent div label
{
  margin-bottom: 25px;
  text-align: center;
  color: #444;
  font-weight: 500;
  font-size: 20px;
}

#sliderCreateEvent > div > input, #seatTitle
{
  padding: 10px 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #d6d1d5;
  font-size: 20px;
}

#sliderCreateEvent > div > div > input
{
  padding: 10px 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #d6d1d5;
  font-size: 20px;
}

.sectionFormEvent .containerButton
{
  justify-content: center;
  z-index: 500;
  margin-top: 50px;
}

.submitEvent
{
  display: flex;
}

.buttonEvent
{
  width: 50%;
  cursor: pointer;
  margin-top: 30px;
  padding: 10px 50px;
  border: none;
  border-radius: 4px;
  background-color: #22223B;
  color: #fefefe;
  font-size: 20px;
  font-weight: 600;
  margin-right: auto;
  margin-left: auto;
}

.buttonSeat
{
  margin: 5px;
  cursor: pointer;
  font-size: 20px;
  background-color: #22223B;
  border: none;
  border-radius: 4px;
  font-weight: 550;
  line-height: 35px;
}

.buttonEvent:hover
{
  background-color: #4A4E69;
  color: #fefefe;
}

.buttonSeat:hover
{
  background-color: #4A4E69;
  color: #fefefe;
}

#sliderCreateEvent
{
  text-align: left;
  position: relative;
  transition: all .4s;
}

#carouselCreateEvent
{
  overflow: hidden;
}

.slide
{
  position: relative;
  float: left;
  margin: 0;
}

.eventTitle
{
  padding: 0 25vw;
}

.eventContent, .eventSeatType, .eventTableType
{
  padding: 0 25vw;
}

.eventDate, .eventStartTime, .eventEndTime, .eventNumberTable
{
  padding: 0 35vw;
}

.eventDate>input, .eventStartTime>input, .eventEndTime>input, .eventNumberTable>input
{
  margin:  0 35%;
  text-align: center;
}

.eventContent
{
  height: 555px;
}

.containerSeat
{
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.labelSeat
{
  text-align: center;
  color: #444;
  font-weight: 500;
  font-size: 20px;
  padding: 5px;
  width: 100%
}

input.currency
{
  text-align: right;
  padding-right: 15px;
}

.input-group .form-control
{
  float: none;
}

.input-group-textEvent
{
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.inputNumberEvent
{
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding: 10px 10px;
  outline: none;
  border: 1px solid #d6d1d5;
  font-size: 20px;
  width: 100px;
}

.eventWrapper ul li
{
  list-style: none;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 500;
  text-align: center;
}

.eventWrapper ul
{
  padding: 10px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  font-weight: 500;
}

.buttoncolumn
{
  width: 100px
}

table .tableCreateEvent td
{
  padding: 8px;
  line-height: 20px;
  vertical-align: top;
  border-top: 1px solid grey;
  box-sizing: border-box;
  font-size: 20px;
  color: black;
}

.tableCreateEvent tr, .tableCreateEvent tbody, .tableCreateEvent thead
{
  box-sizing: border-box;
  border-spacing: 0;
  border-collapse: collapse;
  font-size: 20px;
  line-height: 20px;
  color: black;
}

.tableCreateEvent tr .btn-group-sm >.btn
{
  padding: 5px;
  font-size: 20px;
  margin: 2px;
}

.slide label, .slide input
{
  line-height: 30px;
}

.slide div
{
  text-align: center;
}

html
{
  overflow-y: scroll;
}

.cardAdmin
{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
  position: relative;

  text-decoration: none;
  font-size: 20px;
  line-height: 20px;
  border-radius: 4px;
  overflow: hidden;
  color: black;
  background-color: white;
  /*box-shadow: grey;*/
  justify-content: center;

  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow;
}

.cardAdmin:hover, .cardAdmin:focus, .cardAdmin:active
{
  box-shadow:
          0 3px 3px 3px gray,
          3px 0 3px 3px gray,
          0 -3px 3px 3px gray,
          -3px 0 3px 3px gray;
}

.adminCreation, .adminManagement
{
  width: 100%;
  justify-content: center;
}

.containerAdmin h1
{
  text-align: center;
}

.adminImage
{
  height: 150px;
}

.divAdminImage
{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-size: 20px;
  font-weight: 800;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  color: rgba(63,48,203, 1);
}

@import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,700');

header
{
  z-index: 50;
}

.dateEventContainerhigh
{
  height: 70px;
  position: relative;
}

@media (max-width: 768px)
{
  .ulEncart
  {
    list-style-type: none;
    border-radius: 4px;
    width: 100%;
    padding: 0;
  }

  .cardAdmin
  {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  .dateEventContainer
  {
    position: absolute;
    right: -40px;
    top: 0;
  }

  .logo
  {
    width: 100%;
  }

  .imageApel, .sloganApel
  {
    width: 100%;
  }

  .firstPartLogo
  {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .imageApel
  {
    height: 100px;
    padding: 5px;
  }

  .sloganApel, .sloganApelp
  {
    margin: 0;
  }

  html, body
  {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Quicksand", sans-serif;
    font-size: 20px;
  }

  #menuToggle
  {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    padding: 10px 0;
  }

  .burger_bar
  {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    margin-left: 10px;
    position: relative;
    background: white;
    border-radius: 3px;
    z-index: 1;

    transform-origin: 4px 0;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
    background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
    opacity 0.55s ease;
  }

  #menuToggle div span:first-child
  {
    transform-origin: 0 0;
  }

  #menuToggle div span:nth-last-child(2)
  {
    transform-origin: 0 100%;
  }

  #menu
  {
    width: 100%;
    margin: 0;
    padding: 0;
    background: rgba(97,196,212, 1);
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    z-index: inherit;
    justify-content: space-between;
  }

  #menu li
  {
    padding: 10px 0;
  }

  #menu li a
  {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav1
  {
    visibility: hidden;
    display: none;
  }

  .nav2
  {
    visibility: visible;
    width: 100%;
    height: 40px;
  }

  nav ul#menu li.navAdmin1
  {
    visibility: hidden;
    display: none;
  }

  nav ul#menu li.navAdmin2
  {
    visibility: visible;
    display: inline-block;
  }

  .nav div.logo .aLink
  {
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    align-items: center;
    z-index: 51;
  }

  .margTop
  {
    margin: 250px 0 50px 0;
  }

  .containerFetesLeft
  {
    padding: 0 20px 0 20px;
    margin: 250px 0 10px 0;
  }

  .containerFetesRight
  {
    padding: 0 20px;
    margin: 10px 0 50px 0;
  }

  .margTopCRR
  {
    margin: -20px 0 50px 0;
  }

  .containerCouncilLeft
  {
    padding: 0 5px 0 5px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }

  .containerCouncilLeft h1
  {
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: white;
    color: #f75f57;
    font-weight: 600;
    font-size: 20px;
    border-bottom: 1px solid #f75f57;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .input-groupClasses, .btnClasses, .boutonClasses
  {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    font-size: 20px;
  }

  .input-group-textClasses
  {
    width: 100%;
    font-size: 20px;
  }

  .containerCouncilRight
  {
    padding: 0 20px;
  }

  .titleReport
  {
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .h1CR
  {
    text-align: center;
    font-size: 20px;
  }

  .larg
  {
    width: 80%;
  }

  .nav
  {
    width: 100%;
    height: auto;
    position: fixed;
    line-height: 30px;
    text-align: center;
    background-color: rgba(97,196,212, 1);
    z-index: 100;
    padding: 0;
    margin: 0;
  }

  .navlinks .liNavlinks .aNavlinks
  {
    text-decoration: none;
    color: white;
    line-height: 20px;
    font-size: 20px;
    font-weight: 500;
    padding: 0;
    display: block;
    height: 100%;
    transition: all 0.4s ease;
    margin-bottom: 0;
  }

  .navlinks .liNavlinks .linkConnect
  {
    font-size: 20px;
    text-decoration: none;
    color: #fff;
    line-height: 20px;
    font-weight: 500;
    padding: 10px 0;
    display: block;
    height: 100%;
    transition: all 0.4s ease;
  }

  .underLinks li .aLink
  {
    padding: 0;
    font-size: 20px;
    color: black;
    font-weight: 500;
  }

  .divLinks
  {
    float: right;
    height: 100%;
  }

  .navlinks
  {
    width: 100%;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
  }

  .navlinks .liNavlinks
  {
    width: auto;
    display: inline-block;
  }

  .underLinks li, .underLinks li .aLink
  {
    display: inherit;
    text-decoration: none;
    text-align: left;
  }

  .navlinks .liNavlinks:hover
  {
    background-color: rgba(40, 192, 212, 1);
  }

  .accordionHeaderInput:checked ~ .underLinks
  {
    display: inherit;
    color: white;
    background-color: rgba(155, 205, 212, 1);
  }

  .underLinks
  {
    display: none;
    position: absolute;
    list-style: none;
    margin: 10px 0 0 0;
    width: 100%;
    padding: 0;
  }

  .navTrigger
  {
    display: none;
  }

  .accordionUlChild
  {
    display: none;
    list-style: none;
    margin: 0;
    transition: all 3s ease;
  }

  .accordionInput:checked ~ .accordionUlChild
  {
    display: block;
    padding: 0;
  }

  .accordionInput, .accordionHeaderInput
  {
    position: absolute;
    opacity: 0;
  }

  .accordionLabel
  {
    position: relative;
    display: flex;
    align-items: center;
  }

  .accordionLiChild
  {
    display: none;
  }

  .nav div.divLinks
  {
    width: 100%;
    height: 0;
    overflow: hidden;
  }
  .nav div.show_list
  {
    height: auto;
    display: none;
  }
  .nav ul#menu
  {
    flex-direction: column;
    width: 100%;
    height: 100vh;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(97,196,212, 1);
    background-position: center top;
  }

  .nav ul#menu li
  {
    width: 100%;
    text-align: right;
  }

  .nav ul#menu li .aLink
  {
    text-align: center;
    width: 100%;
    font-size: 20px;
    padding: 0;
  }

  .nav div.media_button
  {
    display: block;
  }

  .footerEncart a, .footerEncart Link
  {
    font-size: 20px;
    padding: 15px;
    border: 1px #fab34d solid;
    letter-spacing: 1px;
    font-weight: 600;
    text-decoration: none;
    color: black;
    transition: 0.5s ease;
    width: 150px;
    text-align: center;
  }

  .footerEncart
  {
    border-top: 1px #fab34d solid;
    background-color: white;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .topEncart
  {
    height: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: white;
    border-bottom: 1px #fab34d solid;
  }

  .table1
  {
    display: none;
    visibility: hidden;
  }

  #tableManagePosts
  {
    display: flex;
    justify-content: center;

    flex-direction: column;
    margin: 0 auto 50px auto;
    padding-left: 10px;
    padding-right: 10px;

    border-collapse: collapse;
    width: 100%;
  }

  .tbodyflex
  {
    display: flex;
    flex-direction: column;
  }

  .table2
  {
    display: flex;
    flex-direction: column;
    visibility: visible;
  }
}

@media (min-width: 769px)
{
  .ulEncart
  {
    list-style-type: none;
    border-radius: 4px;
    width: 100%;
    padding: 0;
  }

  .cardAdmin
  {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  .logo
  {
    width: 100%;
  }

  .dateEventContainer
  {
    position: absolute;
    right: -40px;
    top: 0;
  }

  .imageApel, .sloganApel
  {
    width: 50%;
  }

  .firstPartLogo
  {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .imageApel
  {
    height: 100px;
    padding: 5px;
  }

  .sloganApel, .sloganApelp
  {
    margin: 0;
  }

  html, body
  {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Quicksand", sans-serif;
    font-size: 20px;
  }

  .nav1
  {
    width: 100%;
  }

  .nav1
  {
    visibility: visible;
    display: flex;
  }

  .nav2
  {
    visibility: hidden;
    display: none;
  }

  nav ul.navlinks li.navAdmin2
  {
    visibility: hidden;
    display: none;
  }

  nav ul.navlinks  li.navAdmin1
  {
    visibility: visible;
    display: inline-block;
  }

  .margTop, .margTopCRR
  {
    margin: 250px 0 50px 0;
  }

  .containerFetesLeft
  {
    padding: 0;
    width: 40%;
  }

  .containerFetesRight
  {
    padding: 0 20px;
    width: 60%;
  }

  .containerCouncilLeft
  {
    padding: 0 20px 0 20px;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .containerCouncilLeft h1
  {
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: white;
    color: #f75f57;
    font-weight: 600;
    font-size: 20px;
    border-bottom: 1px solid #f75f57;
    margin-top: 0;
    margin-bottom: 50px;
  }

  .input-groupClasses, .btnClasses, .boutonClasses
  {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    font-size: 20px;
  }

  .input-group-textClasses
  {
    width: 100%;
    font-size: 20px;
  }

  .containerCouncilRight
  {
    padding: 0 5%;
    width: 60%;
  }

  .titleReport
  {
    font-size: 20px;
    text-align: center;
    font-weight: 700;
  }

  .nav div.logo .aLink
  {
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    align-items: center;
    z-index: 51;
  }

  .nav
  {
    width: 100%;
    height: auto;
    position: fixed;
    line-height: 30px;
    text-align: center;
    background-color: rgba(97,196,212, 1);
    z-index: 100;
    padding: 0;
    margin: 0;
  }

  .navlinks .liNavlinks .aNavlinks
  {
    text-decoration: none;
    color: #fff;
    line-height: 80px;
    font-size: 20px;
    font-weight: 500;
    padding: 5px 10px;
    display: block;
    height: 100%;
    transition: all 0.4s ease;
    margin-bottom: 0;
  }

  .navlinks .liNavlinks .linkConnect
  {
    font-size: 20px;
    text-decoration: none;
    color: #fff;
    line-height: 80px;
    font-weight: 500;
    padding: 5px 10px;
    display: block;
    height: 100%;
    transition: all 0.4s ease;
  }

  .liNavlinks label::after
  {
    border-top: 8px solid rgba(255,255,255, 0.5);
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    content: "";
    vertical-align: baseline;
    margin-top: 40px;
    margin-left: 10px;
    display: block;
    float: right;
  }

  .accordionLi label::after
  {
    border-top: 8px solid rgba(0,0,0, 0.5);
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    content: "";
    vertical-align: baseline;
    margin-top: 13px;
    margin-left: 10px;
    display: block;
    float: right;
  }

  .underLinks li .aLink
  {
    padding: 10px 15px;
    font-size: 20px;
    color: black;
    font-weight: 500;
  }

  .divLinks
  {
    float: right;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .navlinks
  {
    width: 100%;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
  }

  .navlinks .liNavlinks
  {
    width: auto;
    display: inline-block;
  }

  .underLinks li, .underLinks li .aLink
  {
    display: inherit;
    text-decoration: none;
    text-align: left;
  }

  .navlinks li .aLink:hover
  {
    background-color: rgba(40, 192, 212, 1);
  }

  .accordionHeaderInput:checked ~ .underLinks
  {
    display: inherit;
    color: white;
    background-color: rgba(155, 205, 212, 1);
  }

  .underLinks
  {
    display: none;
    position: absolute;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .navTrigger
  {
    display: none;
  }

  .accordionUlChild
  {
    display: none;
    list-style: none;
    margin: 0;
    transition: all 3s ease;
  }

  .accordionInput:checked ~ .accordionUlChild
  {
    display: block;
  }

  .accordionInput, .accordionHeaderInput
  {
    position: absolute;
    opacity: 0;
  }

  .accordionLabel
  {
    position: relative;
    display: flex;
    align-items: center;
  }

  .accordionLiChild
  {
    display: none;
  }

  .footerEncart a, .footerEncart Link
  {
    font-size: 20px;
    padding: 15px;
    border: 1px #fab34d solid;
    letter-spacing: 1px;
    font-weight: 600;
    text-decoration: none;
    color: black;
    transition: 0.5s ease;
    width: 150px;
    text-align: center;
  }

  .footerEncart
  {
    border-top: 1px #fab34d solid;
    background-color: white;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
  }

  .topEncart
  {
    height: 40px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    background-color: white;
    border-bottom: 1px #fab34d solid;
  }

  .table1
  {
    display: none;
    visibility: hidden;
  }

  #tableManagePosts
  {
    display: flex;
    justify-content: center;

    flex-direction: column;
    margin: 0 auto 50px auto;
    padding-left: 50px;
    padding-right: 50px;

    border-collapse: collapse;
    width: 100%;
  }

  .tbodyflex
  {
    display: flex;
    flex-direction: column;
  }

  .table2
  {
    display: flex;
    flex-direction: column;
    visibility: visible;
  }
}

@media (min-width: 992px)
{
  .table1
  {
    display: none;
    visibility: hidden;
  }

  #tableManagePosts
  {
    display: flex;
    justify-content: center;

    flex-direction: column;
    margin: 0 auto 50px auto;
    padding-left: 25%;
    padding-right: 25%;

    border-collapse: collapse;
    width: 100%;
  }

  .tbodyflex
  {
    display: flex;
    flex-direction: column;
  }

  .table2
  {
    display: flex;
    flex-direction: column;
    visibility: visible;
  }

  html, body
  {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Quicksand", sans-serif;
    font-size: 20px;
  }

  .nav1
  {
    width: 100%;
    display: flex;
  }

  .nav1
  {
    visibility: visible;
  }

  .nav2
  {
    visibility: hidden;
    display: none;
  }

  .container
  {
    margin: 0;
  }

  /* Utilisé pour commissions fêtes */
  .ulPost
  {
    list-style-type: none;
    padding: 0;
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
  }

  .margTop,.margTopCRR
  {
    margin: 250px 0 50px 0;
  }

  .containerCouncilLeft
  {
    padding: 0 20px 0 20px;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    width: 40%;
  }

  .containerCouncilRight
  {
    padding: 0 5%;
    width: 60%;
  }

  .nav div.logo .aLink
  {
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    align-items: center;
    z-index: 51;
  }

  .nav
  {
    width: 100%;
    height: auto;
    position: fixed;
    line-height: 30px;
    text-align: center;
    background-color: rgba(97,196,212, 1);
    z-index: 100;
    padding: 0;
    margin: 0;
  }

  .navlinks .liNavlinks .aNavlinks
  {
    text-decoration: none;
    color: #fff;
    line-height: 80px;
    font-size: 20px;
    font-weight: 500;
    padding: 5px 10px;
    display: block;
    height: 100%;
    transition: all 0.4s ease;
    margin-bottom: 0;
  }

  .navlinks .liNavlinks .linkConnect
  {
    font-size: 20px;
    text-decoration: none;
    color: #fff;
    line-height: 80px;
    font-weight: 500;
    padding: 5px 10px;
    display: block;
    height: 100%;
    transition: all 0.4s ease;
  }

  .liNavlinks label::after
  {
    border-top: 8px solid rgba(255,255,255, 0.5);
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    content: "";
    vertical-align: baseline;
    margin-top: 40px;
    margin-left: 10px;
    display: block;
    float: right;
  }

  .accordionLi label::after
  {
    border-top: 8px solid rgba(0,0,0, 0.5);
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    content: "";
    vertical-align: baseline;
    margin-top: 13px;
    margin-left: 10px;
    display: block;
    float: right;
  }

  .underLinks li .aLink
  {
    padding: 10px 15px;
    font-size: 20px;
    color: black;
    font-weight: 500;
  }

  .divLinks
  {
    float: right;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .navlinks
  {
    width: 100%;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
  }

  .navlinks .liNavlinks
  {
    width: auto;
    display: inline-block;
  }

  .underLinks li, .underLinks li .aLink
  {
    display: inherit;
    text-decoration: none;
    text-align: left;
  }

  .navlinks li .aLink:hover
  {
    background-color: rgba(40, 192, 212, 1);
  }

  .accordionHeaderInput:checked ~ .underLinks
  {
    display: inherit;
    color: white;
    background-color: rgba(155, 205, 212, 1);
  }

  .underLinks
  {
    display: none;
    position: absolute;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .navTrigger
  {
    display: none;
  }

  .accordionUlChild
  {
    display: none;
    list-style: none;
    margin: 0;
    transition: all 3s ease;
  }

  .accordionInput:checked ~ .accordionUlChild
  {
    display: block;
  }

  .accordionInput, .accordionHeaderInput
  {
    position: absolute;
    opacity: 0;
  }

  .accordionLabel
  {
    position: relative;
    display: flex;
    align-items: center;
  }

  .accordionLiChild
  {
    display: none;
  }
}

@media (min-width: 1400px)
{
  html, body
  {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Quicksand", sans-serif;
    font-size: 20px;
  }

  .table1
  {
    display: flex;
    visibility: visible;
  }

  #tableManagePosts
  {
    display: flex;
    justify-content: center;

    flex-direction: column;
    margin: 0 auto 50px auto;
    padding-left: 50px;
    padding-right: 50px;

    border-collapse: collapse;
    width: 100%;
  }

  .tbodyflex
  {
    flex-direction: row;
  }

  .table2
  {
    display: none;
    visibility: hidden;
  }

  .logo
  {
    width: 50%;
  }

  .nav1
  {
    visibility: visible;
    display: flex;
    width: 50%;
  }

  .nav2
  {
    visibility: hidden;
    display: none;
  }

  .container
  {
    margin: 0;
  }

  .margTop, .margTopCRR
  {
    margin: 150px 0 50px 0;
  }

  .containerCouncilLeft
  {
    padding: 0 20px 0 20px;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }

  .containerCouncilRight
  {
    padding: 0 5%;
    width: 60%;
  }

  .nav div.logo .aLink
  {
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    align-items: center;
    z-index: 51;
  }

  .nav
  {
    width: 100%;
    height: auto;
    position: fixed;
    line-height: 30px;
    text-align: center;
    background-color: rgba(97,196,212, 1);
    z-index: 100;
    padding: 0;
    margin: 0;
  }

  .navlinks .liNavlinks .aNavlinks
  {
    text-decoration: none;
    color: #fff;
    line-height: 80px;
    font-size: 20px;
    font-weight: 500;
    padding: 5px 10px;
    display: block;
    height: 100%;
    transition: all 0.4s ease;
    margin-bottom: 0;
  }

  .navlinks .liNavlinks .linkConnect
  {
    font-size: 20px;
    text-decoration: none;
    color: #fff;
    line-height: 80px;
    font-weight: 500;
    padding: 5px 10px;
    display: block;
    height: 100%;
    transition: all 0.4s ease;
  }

  .liNavlinks label::after
  {
    border-top: 8px solid rgba(255,255,255, 0.5);
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    content: "";
    vertical-align: baseline;
    margin-top: 40px;
    margin-left: 10px;
    display: block;
    float: right;
  }

  .accordionLi label::after
  {
    border-top: 8px solid rgba(0,0,0, 0.5);
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    content: "";
    vertical-align: baseline;
    margin-top: 13px;
    margin-left: 10px;
    display: block;
    float: right;
  }

  .underLinks li .aLink
  {
    padding: 10px 15px;
    font-size: 20px;
    color: black;
    font-weight: 500;
  }

  .divLinks
  {
    float: right;
    height: 100%;
  }

  .navlinks
  {
    width: 100%;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
  }

  .navlinks .liNavlinks
  {
    width: auto;
    display: inline-block;
  }

  .underLinks li, .underLinks li .aLink
  {
    display: inherit;
    text-decoration: none;
    text-align: left;
  }

  .navlinks li .aLink:hover
  {
    background-color: rgba(40, 192, 212, 1);
  }

  .accordionHeaderInput:checked ~ .underLinks
  {
    display: inherit;
    color: white;
    background-color: rgba(155, 205, 212, 1);
  }

  .underLinks
  {
    display: none;
    position: absolute;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .navTrigger
  {
    display: none;
  }

  .accordionUlChild
  {
    display: none;
    list-style: none;
    margin: 0;
    transition: all 3s ease;
  }

  .accordionInput:checked ~ .accordionUlChild
  {
    display: block;
  }

  .accordionInput, .accordionHeaderInput
  {
    position: absolute;
    opacity: 0;
  }

  .accordionLabel
  {
    position: relative;
    display: flex;
    align-items: center;
  }

  .accordionLiChild
  {
    display: none;
  }
}

@media (min-width: 1600px)
{
  .containerCouncilRight
  {
    padding: 0 50px;
  }

  .nav div.logo .aLink
  {
    font-size: 20px;
  }

  .navlinks .liNavlinks .aNavlinks
  {
    font-size: 20px;
  }

  .divLinks
  {
    margin-left: auto;
    margin-right: auto;
  }
}

.postAttachPlace
{
  position: absolute;
  top: 30px;
  right: -55px;
  z-index: 10;
}

.postAttachPlaceContainer
{
  position: relative;
}


.postAttachCouncil
{
  position: relative;
  z-index: 10;
}

.postAttachCouncilContainer
{
  position: relative;
}

.titleAttachPlace
{
  position: absolute;
  top: 70px;
  right: -105px;
  font-size: 20px;
  z-index: 15;
}

.titleAttachCouncil
{
  position: absolute;
  top: -30px;
  font-size: 20px;
  z-index: 15;
}